import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import GridContainer from '../Grid/GridContainer.jsx';
import GridItem from '../Grid/GridItem.jsx';
import Image from '../Image';
import NoProjects from '../NoProjects';
import { useTranslation } from 'react-i18next';
import GetApp from '@material-ui/icons/Add';

const GridView = ({ ...props }) => {
  const { t } = useTranslation();
  const { gridData, isLoading, programLink } = props;

  return (
    <GridContainer style={{ marginBottom: '60px' }} spacing={4}>
      {gridData && gridData.length > 0
        ? [
            ...(gridData || []).map((analytic, key) => (
              <GridItem xs={12} sm={6} md={6} lg={4} key={key}>
                <div className="sector-wrapper">
                  <Link to={programLink + '/analytic' + analytic.url}>
                    <Image imageName={analytic.image} />
                    <div className="sector-text font-medium-sector">
                      <span>
                        {' '}
                        {analytic?.langKey
                          ? t(analytic?.langKey)
                          : analytic.title}{' '}
                      </span>
                    </div>
                    <div className="overlay">
                      <GetApp />
                    </div>
                  </Link>
                </div>
              </GridItem>
            )),
          ]
        : !isLoading && <NoProjects data="Analytics" />}
    </GridContainer>
  );
};

GridView.propTypes = {
  gridData: PropTypes.any,
  totalProjects: PropTypes.number,
  onPageNumberChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  isLoading: PropTypes.bool,
  programLink: PropTypes.string,
  programCode: PropTypes.string,
  handleFavoriteProjects: PropTypes.func,
  favoriteProjects: PropTypes.array,
};

export default GridView;
